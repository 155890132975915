import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { UploadImageResponse } from '../models/upload-image.model';
import { UPLOAD_IMAGE_ENDPOINT } from 'app/core/constants/api-endpoint.const';
import { ApiService } from 'app/core/services/api.service';
import { TDSMessageService } from 'tds-ui/message';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {
  private readonly MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

  constructor(
    private readonly apiService: ApiService,
    private readonly msgService: TDSMessageService
  ) { }

  /**
   * Upload image to server
   * @param file 
   * @returns 
   */
  uploadImage(file: File): Observable<UploadImageResponse> {
    const formData = new FormData();
    formData.append('file', file);

    return this.apiService.post<UploadImageResponse>(UPLOAD_IMAGE_ENDPOINT, formData);
  }

  validateImageType(file: File): Observable<boolean> {
    const validTypes = ['jpeg', 'jpg', 'png', 'heic'];
    const ext = file.name.split('.').pop();
    if (!validTypes.includes(ext!)) {
      this.msgService.error('Chỉ hỗ trợ image/jpeg, image/jpg, image/png, image/heic');
      return of(false);
    }

    return of(true);
  }

  private validateImageDimension(file: File): Observable<boolean> {
    return new Observable(obs => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          const { height, width } = img;

          obs.next(height <= 600 && width <= 600);
          obs.complete();
        };
        img.onerror = () => {
          obs.next(false);
          obs.complete();
        };
      }
    })
  }

  checkImageDimension(file: File): Observable<boolean> {
    return this.validateImageDimension(file).pipe(
      switchMap((isValidDimension: boolean) => {
        if (!isValidDimension) {
          return of(false);
        }
        return of(true);
      })
    );
  }

  resizeImage(file: File): Observable<File> {
    return new Observable<File>(observer => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions
        if (width > 600 || height > 600) {
          const ratio = Math.min(600 / width, 600 / height);
          width *= ratio;
          height *= ratio;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert to file with reduced quality if needed
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              observer.next(resizedFile);
              observer.complete();
            }
          },
          file.type,
        );
      };
    });
  }

  processImageSize(file: File): Observable<File> {
    return new Observable<File>(observer => {
      // If file size is less than 2MB, return the file as is
      if (file.size <= this.MAX_FILE_SIZE) {
        observer.next(file);
        observer.complete();
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        try {
          let processedFile = await this.compressImage(file, img);
          URL.revokeObjectURL(img.src);
          observer.next(processedFile);
          observer.complete();
        } catch (error) {
          URL.revokeObjectURL(img.src);
          observer.error(error);
        }
      };

      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        observer.error(new Error('Failed to load image'));
      };
    });
  }

  private async compressImage(file: File, img: HTMLImageElement): Promise<File> {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0, img.width, img.height);

    const qualitySteps = [0.9, 0.8, 0.7, 0.6, 0.5, 0.4]; // Quality steps to try

    // Try each quality step until the desired size is reached
    for (const quality of qualitySteps) {
      try {
        const compressedFile = await this.createCompressedFile(canvas, file, quality);
        if (compressedFile.size <= this.MAX_FILE_SIZE) {
          return compressedFile;
        }
      } catch (error) {
        console.error('Compression failed at quality:', quality);
      }
    }

    // If the image cannot be reduced below 2MB after trying all quality steps
    throw new Error('Cannot reduce image size below 2MB');
  }

  private createCompressedFile(canvas: HTMLCanvasElement, originalFile: File, quality: number): Promise<File> {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const compressedFile = new File([blob], originalFile.name, {
              type: originalFile.type,
              lastModified: Date.now(),
            });
            resolve(compressedFile);
          } else {
            reject(new Error('Compression failed'));
          }
        },
        originalFile.type,
        quality
      );
    });
  }

  /**
   * 
   * @param file 
   * @returns 
   */
  resizeImageProduct(file: File): Observable<File> {
    return new Observable<File>(observer => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions
        if (width > 4000 || height > 4000) {
          const ratio = Math.min(4000 / width, 4000 / height);
          width *= ratio;
          height *= ratio;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert to file with reduced quality if needed
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              observer.next(resizedFile);
              observer.complete();
            }
          },
          file.type,
        );
      };
    });
  }
}
